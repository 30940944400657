
[data-v-5f0ab907] .ivu-input {
  height: 40px;
}
[data-v-5f0ab907] .ivu-input-prefix i,
.ivu-input-suffix i[data-v-5f0ab907] {
  line-height: 40px;
}
[data-v-5f0ab907] .ivu-btn-primary {
  height: 40px;
}
[data-v-5f0ab907] .ivu-input-icon {
  height: 40px;
  line-height: 40px;
}
.login-layout[data-v-5f0ab907] {
  background: url(/static/images/login-319b6c7c.png) no-repeat center;
  background-size: cover;
}
.login-layout .login[data-v-5f0ab907] {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.login-layout .login .login-custom[data-v-5f0ab907] {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
}
.login-layout .login .login-custom .login-logo[data-v-5f0ab907] {
  height: 50px;
  object-fit: contain;
}
.login-layout .login .login-custom .login-img[data-v-5f0ab907] {
  width: 590px;
  height: 330px;
  background: url(/static/images/login-img-9b4a4b91.png) no-repeat center;
  background-size: contain;
}
.login-layout .login .login-form-content[data-v-5f0ab907] {
  position: relative;
  margin-left: 50px;
  width: 360px;
  height: 380px;
  box-shadow: 0 0 24px 0 rgba(94, 107, 119, 0.24);
  border-radius: 10px;
  background-color: #ffffff;
  padding: 30px 33px;
}
.login-layout .login .login-form-content .form-top[data-v-5f0ab907] {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 12px;
}
.login-layout .login .login-form-content .form-top .title[data-v-5f0ab907] {
  height: 16px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
}
.login-layout .login .login-form-content .error-alert[data-v-5f0ab907] {
  margin-bottom: -10px !important;
  padding: 8px 10px 8px 38px;
}
.login-layout .login .login-form-content .login-form[data-v-5f0ab907] {
  margin-top: 24px;
}
.login-layout .login .login-form-content .login-form .password-item[data-v-5f0ab907] {
  margin-bottom: 16px;
}
.login-layout .login .login-form-content .login-form .remember-item[data-v-5f0ab907] {
  margin-bottom: 7px;
}
.login-layout .login .login-form-content .login-form .remember-item[data-v-5f0ab907] .ivu-form-item-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.login-layout .login .login-form-content .login-form .remember-item[data-v-5f0ab907] .ivu-form-item-content .remember-me {
  color: #737373;
}
.login-layout .login .login-form-content .bottom-content[data-v-5f0ab907] {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 40px;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 0 30px;
  color: #737373;
  background-color: #f5f7f9;
}
.login-layout .login .login-form-content .bottom-content .register[data-v-5f0ab907] {
  font-size: 13px;
  height: 14px;
  line-height: 1.08;
  letter-spacing: 0.2px;
  color: #737373;
}
.login-layout .login .login-form-content .bottom-content .internal-login[data-v-5f0ab907] {
  font-size: 13px;
  height: 14px;
  line-height: 1.08;
  letter-spacing: 0.2px;
  color: #00BBBD;
}
.login-layout .login .login-form-content .bottom-content .internal-login[data-v-5f0ab907]:hover {
  cursor: pointer;
}
.login-layout .login .login-form-content .bottom-content .wechat[data-v-5f0ab907] {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  align-items: center;
}
.login-layout .login .login-form-content .bottom-content .wechat .wechat-logo[data-v-5f0ab907] {
  width: 16px;
  height: 16px;
  background: url(/static/images/wechat-9ad411aa.png) no-repeat center;
  background-size: contain;
  margin-right: 4px;
}
